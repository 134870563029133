import React, {useState} from "react";
import * as styles from "./our-cars.module.scss";
import Layout from "../../components/layout/layout";
import {graphql} from "gatsby";
import {OurCarsQuery} from "../../../graphql-types";
import PageCover from "../../components/common/page-cover/page-cover";
import LinksGroupSide from "../../components/common/links-group-side/links-group-side";
import RichDatoCmsContent from "../../components/rich-dato-cms-content/rich-dato-cms-content";
import useWindowWidth from "../../hooks/use-window-width";

type ReactProps = {
    data: OurCarsQuery
}

const OurCarsPage:React.FC<ReactProps> = ({data}) => {

    const isMobile = useWindowWidth() <= 1200;
    const [selectors, setSelectors] = useState(
        [{id: 1, title: data.datoCmsNosVehiculesNew.mainPageButtonTitle, content: data.datoCmsNosVehiculesNew.mainContent, selected: true, link: "/location-autocar"},
            {id: 2, title: data.datoCmsNosVehiculesNew.minibusButtonTitle, content: data.datoCmsNosVehiculesNew.minibusContent, selected: false, link: "/location-autocar/minibus"},
            {id: 3, title: data.datoCmsNosVehiculesNew.autocarEcoButtonTitle, content: data.datoCmsNosVehiculesNew.autocarEcoContent, selected: false, link: "/location-autocar/autocar-eco"},
            {id: 4, title: data.datoCmsNosVehiculesNew.autocarMidicarConfortButtonTitle, content: data.datoCmsNosVehiculesNew.autocarMidicarConfortContent, selected: false, link: "/location-autocar/autocar-midicar-confort"},
            {id: 5, title: data.datoCmsNosVehiculesNew.autocarConfortGrandeCapaciteButtonTitle, content: data.datoCmsNosVehiculesNew.autocarConfortGrandeCapaciteContent, selected: false, link: "/location-autocar/autocar-confort-grande-capacite"},
        ]);
    const [sContent, setScontent] = useState(selectors[0].content)

    return (
        <Layout data={data.datoCmsFooter} stickyButtonsData={data.datoCmsWebStickyButton} chatbotData={data.datoCmsChatbot} seo={data.datoCmsNosVehiculesNew.seo}        pageTemplate="our car">
            <PageCover
                title={data.datoCmsNosVehiculesNew.cover[0] && data.datoCmsNosVehiculesNew.cover[0].title}
                image={isMobile ? data.datoCmsNosVehiculesNew.cover[0] && data.datoCmsNosVehiculesNew.cover[0].mobileImage : data.datoCmsNosVehiculesNew.cover[0] && data.datoCmsNosVehiculesNew.cover[0].backgroundImage}
            />
            <section className={styles.pageContainer}>
                <div className={styles.pageContent}>
                    {!isMobile && <LinksGroupSide data={data.datoCmsNosVehiculesNew.linksGroup} />}
                    <div className={styles.content}>
                        <div className={styles.buttonsContainer}>
                            {selectors.map((selector, index) => {
                                return (
                                    <a
                                        aria-label={selector.title}
                                        key={selector.id}
                                        className={selector.selected ? styles.buttonSelected : styles.button}
                                        href={selector.link}
                                    >
                                        {selector.title}
                                    </a>
                                )
                            })}
                        </div>
                        {sContent && <RichDatoCmsContent data={sContent} />}
                    </div>
                    {isMobile && <LinksGroupSide data={data.datoCmsNosVehiculesNew.linksGroup} />}
                </div>
            </section>
        </Layout>
    )

}

export const fragment = graphql`
    fragment OurCarsFields on DatoCmsNosVehiculesNew {
        seo {
          description
          title
        }
        cover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            },
            mobileImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        linksGroup {
            id
            title
            titleAttribute
            slug
            mainColor {
                hex
            }       
            icon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            iconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIcon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        mainContent {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
        mainPageButtonTitle
        minibusSeo {
          description
          title
        }
        minibusPageCover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        minibusButtonTitle
        minibusContent {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
        autocarEcoSeo {
          description
          title
        }
        autocarEcoPageCover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        autocarEcoButtonTitle
        autocarEcoContent {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
        autocarMidicarConfortSeo {
          description
          title
        }
        autocarMidicarConfortPageCover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        autocarMidicarConfortButtonTitle
        autocarMidicarConfortContent {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
        autocarConfortGrandeCapaciteSeo {
          description
          title
        }
        autocarConfortGrandeCapacitePageCover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        autocarConfortGrandeCapaciteButtonTitle
        autocarConfortGrandeCapaciteContent {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
    }
`

export const pageQuery = graphql`
    query OurCars($locale: String!) {  
        datoCmsFooter(locale: {  eq: $locale }) {
            ...FooterFields
        }
        datoCmsNosVehiculesNew(locale: { eq: $locale }) {
            ...OurCarsFields
        }
        datoCmsWebStickyButton(locale: {  eq: $locale }) {
            ...StickyButtonsFields
        }
        datoCmsChatbot(locale: { eq: $locale }) {
            ...ChatbotFields
        }
    }
`


export default OurCarsPage;